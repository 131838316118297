import { Components, Theme } from '@mui/material'
import { colors } from 'tailwind.config'

export const MuiTextField: Components<Theme>['MuiTextField'] = {
  defaultProps: {
    variant: 'outlined',
    color: 'secondary',
  },
  variants: [
    {
      props: { variant: 'outlined', color: 'secondary' },
      style: () => {
        return {
          '.MuiFormLabel-root.Mui-focused': {
            color: colors.black,
          },
          '.MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: colors.black,
          },
        }
      },
    },
  ],
}
