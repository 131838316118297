import { Components, Theme } from '@mui/material'
import { colors } from 'tailwind.config'

export const MuiAlert: Components<Theme>['MuiAlert'] = {
  styleOverrides: {
    standardSuccess: {
      backgroundColor: colors.green[700],
      color: colors.white,
    },
    standardError: {
      backgroundColor: colors.alert.error,
      color: colors.white,
    },
    standardWarning: {
      backgroundColor: colors.orange[600],
      color: colors.white,
    },
    standardInfo: {
      backgroundColor: colors.blue[900],
      color: colors.white,
    },
  },
}
