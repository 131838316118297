'use client'

// When using TypeScript 4.x and above
import type {} from '@mui/lab/themeAugmentation'

import { createTheme } from '@mui/material/styles'
import { palette } from '@/components/Theme/overrides/palette'
import { components } from '@/components/Theme/overrides/components'
import { breakpoints } from '@/components/Theme/overrides/breakpoints'

const finalTheme = createTheme({
  components,
  palette,
  breakpoints,
  typography: {
    fontFamily: 'inherit',
  },
})

export default finalTheme
