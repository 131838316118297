import { PaletteOptions } from '@mui/material'
import { colors } from 'tailwind.config'

declare module '@mui/material/styles' {
  interface Palette {
    tertiary: Palette['primary']
    white: Palette['primary']
    black: Palette['primary']
  }
  interface PaletteOptions {
    tertiary: PaletteOptions['primary']
    white: PaletteOptions['primary']
    black: PaletteOptions['primary']
  }
}

export const palette: PaletteOptions = {
  primary: {
    dark: colors.blue[800],
    main: colors.blue['600'],
    light: colors.blue['400'],
  },
  secondary: {
    main: colors.blue['600'],
    light: '#4CB8E5',
  },
  tertiary: {
    main: '#758E99',
    dark: '#485961',
  },
  white: {
    main: colors.white,
    dark: '#F0F3F4',
  },
  black: {
    main: colors.black,
  },
}
