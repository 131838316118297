'use client'

import { signOut, useSession } from 'next-auth/react'
import { useEffect } from 'react'

export const RefreshSession = () => {
  const { data: session, status } = useSession()

  useEffect(() => {
    // check if the session expired
    if (session?.expiredSession) {
      // Sign out here
      signOut({ callbackUrl: '/' })
    }
  }, [status])

  return <></>
}
