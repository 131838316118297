import { FeatureToggleContext } from '@/components/FeatureFlags/FeatureToggleContext'
import React, { useState } from 'react'
import { FeatureFlagEnum } from '@/components/FeatureFlags/FeatureFlagEnum'

type Props = {
  enabledFeatures?: FeatureFlagEnum[]
  children: any
}

export const FeatureToggle = ({ children, enabledFeatures = [] }: Props) => {
  const [activeFeatures, setFeatures] =
    useState<FeatureFlagEnum[]>(enabledFeatures)

  const enableFeature = (featureName: FeatureFlagEnum) => {
    setFeatures([...activeFeatures, featureName])
  }

  const disableFeature = (featureName: FeatureFlagEnum) => {
    setFeatures(activeFeatures.filter((feature) => feature !== featureName))
  }

  return (
    <FeatureToggleContext.Provider
      value={{ enabledFeatures: activeFeatures, enableFeature, disableFeature }}
    >
      {children}
    </FeatureToggleContext.Provider>
  )
}
