import { Components, Theme } from '@mui/material'
import { colors } from 'tailwind.config'

//TODO
//button variant found on /documentation text-dark-grey hover:bg-grey hover:text-white
//sx={{ borderColor: '#758E99' }}

// alt variant cu  sx={{ p: 0 }}, pentru SignInForm
export const MuiButton: Components<Theme>['MuiButton'] = {
  defaultProps: {
    disableElevation: true,
    variant: 'contained',
    color: 'secondary',
    size: 'medium',
  },
  styleOverrides: {
    root: {
      '&.MuiButton-root': {
        fontWeight: '600',
        textTransform: 'none',
        borderRadius: '100px',
        fontFamily: 'inherit',
      },
    },
  },
  variants: [
    {
      props: { variant: 'contained', color: 'primary' },
      style: () => {
        return {
          backgroundColor: colors.blue['600'],
          ':hover': {
            backgroundColor: colors.blue['400'],
          },
          color: colors.white,
        }
      },
    },
    {
      props: { variant: 'contained', color: 'secondary' },
      style: () => {
        return {
          backgroundColor: colors.blue['600'],
          ':hover': {
            backgroundColor: '#4CB8E5',
          },
          color: colors.white,
        }
      },
    },
    {
      props: { variant: 'contained', color: 'warning' },
      style: () => {
        return {
          backgroundColor: colors.white,
          ':hover': {
            backgroundColor: '#F0F3F4',
          },
          color: '#485961',
        }
      },
    },
    {
      props: { variant: 'outlined', color: 'info' },
      style: () => {
        return {
          borderColor: colors.neutral[400],
          color: colors.neutral[600],
          ':hover': {
            backgroundColor: colors.neutral[100],
          },
        }
      },
    },
    {
      props: { variant: 'outlined', color: 'warning' },
      style: () => {
        return {
          borderColor: colors.white,
          color: colors.white,
          ':hover': {
            backgroundColor: colors.white,
            borderColor: colors.white,
            color: '#485961',
          },
        }
      },
    },
    {
      props: { variant: 'text', color: 'warning' },
      style: () => {
        return {
          borderColor: '#758E99',
          backgroundColor: colors.alert.error,
          color: colors.white,
          ':hover': {
            backgroundColor: colors.alert.error,
            borderColor: colors.white,
            color: colors.white,
          },
        }
      },
    },
    {
      props: { variant: 'text', color: 'primary' },
      style: () => {
        return {
          borderColor: 'none',
          backgroundColor: 'none',
          color: colors.blue['600'],
          ':hover': {
            backgroundColor: '#0099DA0D',
            borderColor: 'none',
          },
        }
      },
    },
    {
      props: { variant: 'switch', color: 'secondary' },
      style: () => {
        return {
          borderColor: 'none',
          backgroundColor: 'none',
          color: colors.neutral['700'],
          ':hover': {
            backgroundColor: colors.white,
            borderColor: 'none',
            color: colors.neutral['500'],
          },
        }
      },
    },
    {
      props: { variant: 'outlined', color: 'success' },
      style: ({ theme }) => {
        const { palette } = theme
        return {
          borderColor: '#758E99',
          color: '#485961',
          ':hover': {
            backgroundColor: '#758E99',
            borderColor: '#758E99',
            color: colors.white,
          },
        }
      },
    },
    {
      props: { size: 'small' },
      style: {
        fontSize: '13px',
        lineHeight: '20px',
        padding: '6px 20px',
      },
    },
    {
      props: { size: 'medium' },
      style: {
        fontSize: '14px',
        lineHeight: '20px',
        padding: '10px 20px',
      },
    },
    {
      props: { size: 'large' },
      style: {
        fontSize: '16px',
        lineHeight: '24px',
        padding: '15px 20px',
      },
    },
    // for the outlined btns, because of the border we need to
    // substract 1px from the padding so the width / height remains the same
    {
      props: { variant: 'outlined', size: 'small' },
      style: {
        padding: '5px 19px',
      },
    },
    {
      props: { variant: 'outlined', size: 'medium' },
      style: {
        padding: '9px 14px',
      },
    },
    {
      props: { variant: 'outlined', size: 'large' },
      style: {
        padding: '14px 19px',
      },
    },
  ],
}
